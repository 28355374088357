<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-0">
              <div class="row">
                <div class="col-md-12 page-loader-parent">
                  <div class="page-loader">
                    <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                  </div>
                </div>
              </div>
              <div class="row content-div d-none2">
                <div class="col-md-12">
                  <div @click="$router.go(-1)" class="d-flex align-items-center pointer">
                    <i class="ti ti-arrow-right font-16 pr-3"></i>
                    <h5 class="m-0">{{ user.name }}</h5>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <hr>
                </div>
                <div class="col-md-8 mb-4">
                  <div class="messages-container">
                    <div class="text-center m-3 loader-old d-none2">
                      <md-progress-spinner :md-diameter="30" :md-stroke="4" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                    <div class="no-messages" v-if="messages.length == 0">
                      <span>لا يوجد رسائل</span>
                    </div>
                    <div v-for="(message, index) in messages" :key="index" class="row message-body">
                      <div
                        :class="current_user_id == message.from_admin_id ? 'col-sm-12 message-main-send' : 'col-sm-12 message-main-received'">
                        <div :class="current_user_id == message.from_admin_id ? 'send' : 'received'">
                          <div v-if="message.message_type == 'text'" class="message-text">
                            <pre>{{ message.body }}</pre>
                          </div>
                          <div v-else-if="message.message_type == 'image'" class="message-text">
                            <a :href="message.attachment.url" download>
                              <img :src="message.attachment.url">
                            </a>
                          </div>
                          <div v-else-if="message.message_type == 'audio'" class="message-text">
                            <audio id="myAudio" controls :src="message.attachment.url"></audio>
                          </div>
                          <div v-else-if="message.message_type == 'file'" class="message-text">
                            <a :href="message.attachment.url" :download="message.attachment.name">
                              <button class="i5tg98hk" tabindex="0" :title="`Download ${message.attachment.name}`"
                                type="button">
                                <div class="sfeitywo">
                                  <div class="fhf7t426">
                                    <div class=""
                                      style="flex-shrink: 0;display: flex;background-repeat: no-repeat;background-size: contain;height: 30px;width: 26px;flex-grow: 0;min-height: 0;flex-basis: auto;min-width: 0;order: 0;">
                                      <div class="sxl192xd"></div>
                                    </div>
                                    <div class="spjzgwxb">
                                      <div class="m1c2hokz">
                                        <span dir="auto">
                                          {{ message.attachment.name }}
                                        </span>
                                      </div>
                                      <div class="e4p1bexh">
                                        <span title="pdf">{{ message.attachment.type }}</span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    </div>
                                    <div style="flex-grow: 0; flex-shrink: 0;"></div>
                                  </div>
                                </div>
                              </button>
                            </a>
                          </div>
                          <span class="message-time pull-right">
                            {{ new Date(message?.created_at).toLocaleTimeString('fr-FR', {
                              hour:
                                '2-digit', minute: '2-digit',
                              hour12: true
                            }) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="file-container">
                    <span class="file-name">
                      <svg height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2 0C0.9 0 0.01 0.9 0.01 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.58 0.59C10.21 0.21 9.7 0 9.17 0H2ZM9 6V1.5L14.5 7H10C9.45 7 9 6.55 9 6ZM4 10C3.44772 10 3 10.4477 3 11C3 11.5523 3.44772 12 4 12H12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10H4ZM10 15C10 14.4477 9.55228 14 9 14H4C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16H9C9.55228 16 10 15.5523 10 15Z"
                          fill="var(--attachment-type-documents-color)"></path>
                      </svg>
                      <span>
                      </span>
                    </span>
                    <svg @click="ClearFile" class="clear-file pointer" viewBox="0 0 24 24" height="24" width="24"
                      preserveAspectRatio="xMidYMid meet" fill="currentColor" enable-background="new 0 0 24 24"
                      xml:space="preserve">
                      <path
                        d="M19.6004 17.2L14.3004 11.9L19.6004 6.60005L17.8004 4.80005L12.5004 10.2L7.20039 4.90005L5.40039 6.60005L10.7004 11.9L5.40039 17.2L7.20039 19L12.5004 13.7L17.8004 19L19.6004 17.2Z">
                      </path>
                    </svg>
                  </div>
                  <div v-if="recording" class="input-containert  justify-content-end">
                    <span class="timer">00:00</span>
                    <div>
                      <img src="../../../../assets/images/download.png">
                    </div>
                    <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      viewBox="0 0 16 22" class="pointer" style="color: #ff7f7f;">
                      <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                        fill="currentColor"></path>
                    </svg>
                    <svg @click="sendMessage()" class="pointer  send-icon" viewBox="0 0 24 24" height="24" width="24"
                      preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24"
                      xml:space="preserve">
                      <path fill="currentColor"
                        d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z">
                      </path>
                    </svg>
                  </div>
                  <div v-else class="input-containert">
                    <div>
                      <label for="file">
                        <svg viewBox="0 0 24 24" class="pointer" height="24" width="24"
                          preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                          enable-background="new 0 0 24 24" xml:space="preserve">
                          <path fill="currentColor"
                            d="M1.816,15.556v0.002c0,1.502,0.584,2.912,1.646,3.972s2.472,1.647,3.974,1.647 c1.501,0,2.91-0.584,3.972-1.645l9.547-9.548c0.769-0.768,1.147-1.767,1.058-2.817c-0.079-0.968-0.548-1.927-1.319-2.698 c-1.594-1.592-4.068-1.711-5.517-0.262l-7.916,7.915c-0.881,0.881-0.792,2.25,0.214,3.261c0.959,0.958,2.423,1.053,3.263,0.215 c0,0,3.817-3.818,5.511-5.512c0.28-0.28,0.267-0.722,0.053-0.936c-0.08-0.08-0.164-0.164-0.244-0.244 c-0.191-0.191-0.567-0.349-0.957,0.04c-1.699,1.699-5.506,5.506-5.506,5.506c-0.18,0.18-0.635,0.127-0.976-0.214 c-0.098-0.097-0.576-0.613-0.213-0.973l7.915-7.917c0.818-0.817,2.267-0.699,3.23,0.262c0.5,0.501,0.802,1.1,0.849,1.685 c0.051,0.573-0.156,1.111-0.589,1.543l-9.547,9.549c-0.756,0.757-1.761,1.171-2.829,1.171c-1.07,0-2.074-0.417-2.83-1.173 c-0.755-0.755-1.172-1.759-1.172-2.828l0,0c0-1.071,0.415-2.076,1.172-2.83c0,0,5.322-5.324,7.209-7.211 c0.157-0.157,0.264-0.579,0.028-0.814c-0.137-0.137-0.21-0.21-0.342-0.342c-0.2-0.2-0.553-0.263-0.834,0.018 c-1.895,1.895-7.205,7.207-7.205,7.207C2.4,12.645,1.816,14.056,1.816,15.556z">
                          </path>
                        </svg>
                      </label>
                      <input type="file" name="file" id="file" class="d-none" accept="image/png, image/gif, image/jpeg">
                    </div>
                    <div class="reply-main">
                      <textarea v-model="message" class="form-control w-100 message" rows="1"
                        :placeholder="$t('Type a message')"></textarea>
                    </div>
                    <div>
                      <svg v-if="!canSend" @click="startRecord()" class="pointer" viewBox="0 0 24 24" height="24"
                        width="24" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                        enable-background="new 0 0 24 24" xml:space="preserve">
                        <path fill="currentColor"
                          d="M11.999,14.942c2.001,0,3.531-1.53,3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531 S8.469,2.35,8.469,4.35v7.061C8.469,13.412,9.999,14.942,11.999,14.942z M18.237,11.412c0,3.531-2.942,6.002-6.237,6.002 s-6.237-2.471-6.237-6.002H3.761c0,4.001,3.178,7.297,7.061,7.885v3.884h2.354v-3.884c3.884-0.588,7.061-3.884,7.061-7.885 L18.237,11.412z">
                        </path>
                      </svg>
                      <svg v-else @click="sendMessage()" class="pointer send-icon" viewBox="0 0 24 24" height="24"
                        width="24" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                        enable-background="new 0 0 24 24" xml:space="preserve">
                        <path fill="currentColor"
                          d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z">
                        </path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="user-info">
                    <img src="../../../../assets/images/avatar.png" width="75">
                    <div>
                      <h6>{{ user.name }} ({{ user.id }})</h6>
                      <h6>الإيميل: {{ user.login_email }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import WhatsAppNewMessage from '../../../../assets/audio/WhatsAppNewMessage.mp3';
import WhatsappMessageSent from '../../../../assets/audio/WhatsappMessageSent.mp3';

export default {
  page: {
    title: "Conversations",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      messages: [],
      message: '',
      user: [],
      title: "Conversations",
      canSend: false,
      recording: false,
      audioContext: null,
      mediaRecorder: null,
      audioChunks: [],
      timer: null,
      audioBlob: null,
      audioAction: '',
      fileSrc: '',
      fileType: '',
      perPage: 10,
      page: 1,
      last_page: 0,
      current_user_id: ''
    };
  },
  methods: {
    Jquery() {
      $(document).on('keyup', 'textarea', function () {
        var val = $(this).val();
        if (val.trim().length > 0) {
          that.canSend = true;
        } else {
          that.canSend = false;
        }
      });
      $(document).on('change', '#file', function () {
        var input = this;
        var val = $(this).val();
        var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
        var filename = $('#file').val().replace(/C:\\fakepath\\/i, '');
        $('.file-container .file-name span').html(filename);
        $('.file-container').css('display', 'flex');
        if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")) {
          that.fileType = 'image';
          var reader = new FileReader();
          reader.onload = function (e) {
            that.fileSrc = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        } else {
          var reader = new FileReader();
          reader.onload = function (e) {
            that.fileSrc = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
          that.fileType = 'file';
        }
        this.message = '';
        $('.message').prop('disabled', true);
        that.canSend = true;
      });
      $('.messages-container').scroll(function () {
        if ($(this).scrollTop() == 0 && that.messages.length > 0 && that.last_page != that.page) {
          $('.loader-old').show();
          var url = `${that.$appUrl}/api/admin/support/get_admin_chat_messages?user_id=${that.$route.params.id}&perPage=${that.perPage}&page=${that.page + 1}`;
          that.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
            that.messages.unshift(...response.data.messages.data.reverse());
            that.page = response.data.messages.current_page;
            $(".messages-container").animate({
              scrollTop: 100
            }, 'fast');
            $('.loader-old').hide();
          });
        } else {
          $('.loader-old').hide();
        }
      });
    },
    sendMessage() {
      if (this.recording) {
        this.audioAction = 'send';
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.recording = false;
      } else {
        if ($('#file').val() != '') {
          if (this.fileType == 'image') {
            this.messages.push({
              from_admin_id: this.current_user_id,
              message_type: 'image',
              attachment:
              {
                'name': 'image',
                'type': 'png',
                'size': '0.0',
                'url': this.fileSrc,
              },
              created_at: new Date()
            });
            const formData = new FormData();
            formData.append('message_type', 'image');
            formData.append('attachment', $('#file').get(0).files[0]);
            formData.append('user_id', this.$route.params.id);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${this.$appUrl}/api/admin/support/send_admin_message`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
            xhr.onload = () => {
              if (xhr.status === 200) {
                var audio = new Audio(WhatsappMessageSent);
                audio.play();
              } else {
                console.error('Error sending audio:', xhr.statusText);
              }
            };
            xhr.send(formData);
          } else {
            var val = $('#file').val();
            var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
            var filename = $('#file').val().replace(/C:\\fakepath\\/i, '');
            this.messages.push({
              from_admin_id: this.current_user_id,
              message_type: 'file',
              attachment:
              {
                'name': filename,
                'type': ext,
                'size': '0.0',
                'url': this.fileSrc,
              },
              created_at: new Date()
            });
            const formData = new FormData();
            formData.append('message_type', 'file');
            formData.append('attachment', $('#file').get(0).files[0]);
            formData.append('user_id', this.$route.params.id);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${this.$appUrl}/api/admin/support/send_admin_message`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
            xhr.onload = () => {
              if (xhr.status === 200) {
                var audio = new Audio(WhatsappMessageSent);
                audio.play();
              } else {
                console.error('Error sending audio:', xhr.statusText);
              }
            };
            xhr.send(formData);
          }
        } else {
          this.messages.push({
            from_admin_id: this.current_user_id,
            message_type: 'text',
            body: this.message,
            created_at: new Date()
          });
          const formData = new FormData();
          formData.append('message_type', 'text');
          formData.append('message', this.message);
          formData.append('user_id', this.$route.params.id);
          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${this.$appUrl}/api/admin/support/send_admin_message`, true);
          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
          xhr.onload = () => {
            if (xhr.status === 200) {
              var audio = new Audio(WhatsappMessageSent);
              audio.play();
            } else {
              console.error('Error sending audio:', xhr.statusText);
            }
          };
          xhr.send(formData);
        }
        this.message = '';
        this.ClearFile();
        this.canSend = false;
      }
      $(".messages-container").animate({
        scrollTop: 9999999
      }, 'slow');
    },
    updateTimer(startTime) {
      this.timer = setInterval(function () {
        var currentTime = new Date().getTime();
        var elapsedTime = new Date(currentTime - startTime);
        var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
        var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
        $('.timer').html(`${minutes}:${seconds}`);
      }, 1000);
    },
    startRecord() {
      this.audioChunks = [];
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          var currentTime = new Date().getTime();
          this.updateTimer(currentTime);
          this.audioContext = new AudioContext();
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = (a) => {
            if (this.audioAction == 'send') {
              this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
              const audioUrl = URL.createObjectURL(this.audioBlob);
              this.messages.push({
                from_admin_id: this.current_user_id,
                message_type: 'audio',
                attachment:
                {
                  'name': 'audio.wav',
                  'type': 'wav',
                  'size': '0.0',
                  'url': audioUrl,
                },
                created_at: new Date()
              });
              const formData = new FormData();
              formData.append('attachment', this.audioBlob, 'recorded_audio.wav');
              formData.append('message_type', 'audio');
              formData.append('user_id', this.$route.params.id);
              const xhr = new XMLHttpRequest();
              xhr.open('POST', `${this.$appUrl}/api/admin/support/send_admin_message`, true);
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
              xhr.onload = () => {
                if (xhr.status === 200) {
                  var audio = new Audio(WhatsappMessageSent);
                  audio.play();
                } else {
                  console.error('Error sending audio:', xhr.statusText);
                }
              };
              xhr.send(formData);
            }
            clearInterval(this.timer);
          };
          this.mediaRecorder.start();
          this.recording = true;
        }).catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    },
    ClearFile() {
      $('.reply-main textarea').prop('disabled', false);
      $('.file-container').css('display', 'none');
      $('#file').val(null);
      that.canSend = false;
    },
    clearRecord() {
      if (this.recording) {
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.recording = false;
      }
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    $('.page-loader-parent').show();
    $('.table-div').hide();
    var user_id = parseInt(this.$route.params.id);
    var url = `${this.$appUrl}/api/admin/support/get_admin_chat_messages?user_id=${this.$route.params.id}&perPage=${this.perPage}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.messages = response.data.messages.data.reverse();
      this.user = response.data.contact;
      this.page = response.data.messages.current_page;
      this.last_page = response.data.messages.last_page;
      this.current_user_id = response.data.admin_id;
      $(".messages-container").animate({
        scrollTop: 9999999
      }, 'slow');
      $('.page-loader-parent').remove();
      $('.content-div').css('display', 'flex');
    });
    this.$pusher.subscribe('messages-channel').bind('new-message', (data) => {
      var message = JSON.parse(data);
      if (message.to_admin_id == this.current_user_id && message.from_admin_id == user_id) {
        this.messages.push(message);
        $(".messages-container").animate({
          scrollTop: 9999999
        }, 'slow');
        var audio = new Audio(WhatsAppNewMessage);
        $('bod').click();
        audio.play();
        this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.user-info {
  display: flex;
  gap: 15px;
  border: 1px solid #dfe5e7;
  padding: 15px 10px;
  align-items: center;
  border-radius: 3px;

  h6 {
    font-size: 12px;
  }
}

.bookings-history {
  border: 1px solid #dfe5e7;
  padding: 15px 10px;
  align-items: center;
  border-radius: 3px;

  .destinations {
    display: flex;
    gap: 15px;

  }
}

.messages-container {
  background: url('~@/assets/images/bg-chat-tile.png');
  background-color: #efeae2;
  padding: 10px 0px;
  max-height: 450px;
  min-height: 450px;
  overflow-y: auto;
  border-radius: 4px 4px 0px 0px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e3e3e3;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ced0d1;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ced0d1;
  }

  .msg-card {
    &.send {
      .inner {
        background: #ced0d1;
        color: #fff;
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
      }
    }

    &.received {
      text-align: -webkit-left;

      .inner {
        background: #e3e3e3;
        color: rgb(0 0 0);
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }

}

.input-containert {
  display: flex;
  border-radius: 0px 0px 4px 4px;
  gap: 15px;
  align-items: center;
  padding: 15px 20px;
  background-color: #f0f2f5;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.div:has(.page-loader-parent) {
  min-height: 350px;
}

.reply-main {
  padding: 2px 5px !important;
  width: 100%;

  textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    padding: 10px !important;
    outline: none;
    border: none;
    text-indent: 5px;
    box-shadow: none;
    height: 100%;
    font-size: 16px;
    border-radius: 8px;

    &:focus {
      outline: none;
      border: none;
      text-indent: 5px;
      box-shadow: none;
    }
  }
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-send {
  padding: 3px 12px;
  margin-left: 40% !important;
  max-width: 60%;
}

.send {
  float: right;
  width: auto !important;
  background: #d9fdd3;
  border-radius: 7.5px 7.5px 0 7.5px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  display: inline-block;
  word-wrap: break-word;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  direction: rtl;
  text-align: initial;

  pre {
    margin-bottom: 0;
    font-family: 'Tajawal';
  }
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.pull-right {
  float: right !important;
}

.received {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 7.5px 7.5px 7.5px 0;
  background: #ffffff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  word-wrap: break-word;
  display: inline-block;
  max-width: 60%;
}

.message-main-received {
  padding: 3px 12px;
  text-align: initial;
}

.rtl {
  .message-main-received {
    padding: 3px 12px;
    text-align: end;
  }
}

.file-container {
  display: none;
  background-color: #f0f2f5;
  padding: 20px 20px;
  border-bottom: 1px solid #dfdfdf;

  .file-name {
    width: 100%;

    svg {
      fill: #5b626b;
      margin-left: 10px;
    }
  }
}

.timer {

  font-size: 18px;
  font-weight: 600;

}

.rtl .send-icon {
  rotate: 180deg;
}

.message-text img {
  width: 100%;
  height: 100%;
  margin: 0px 0px;
  border-radius: 5px;
  object-fit: cover;
  padding: 0;
}

.message-text:has(img) {
  width: 330px;
  height: 247.5px;
}

.received:has(audio),
.send:has(audio) {
  background-color: transparent;
  padding: 0 !important;

  .message-text {
    padding: 0 !important;

    audio {
      padding: 0 !important;
      height: 54px;
    }
  }
}

.send {
  .sfeitywo {
    background-color: #d1f4cc;
  }
}

.i5tg98hk {
  background-color: transparent;
  border-left: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: inherit;
  align-items: stretch;
  display: flex;
  border-bottom: 0;
  border-top: 0;
  outline: none;
  background: none;
  border: 0;
  margin: 0;
}

.sfeitywo {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  display: flex;
  background-color: #f5f6f6;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
}

.fhf7t426 {
  display: flex;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 13px;
  padding-bottom: 13px;
  flex-wrap: nowrap;
  min-width: 0;
  align-items: flex-start;
  flex-direction: row;
}

.spjzgwxb {
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 0;
  min-height: 0;
  margin-bottom: 0;
  flex-shrink: 1;
  min-width: 0;
  flex-basis: auto;
  order: 0;
}

.m1c2hokz {
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}

.e4p1bexh {
  padding-left: 0;
  padding-right: 0;
  padding-top: 3px;
  font-size: 12px;
  height: 18px;
  line-height: 15px;
  padding-bottom: 3px;
  color: #8696a0;
}

.sxl192xd {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.messages-container:has(.no-messages) {
  position: relative;
  align-items: center;
  display: grid;

  .no-messages {
    text-align: center;

    span {
      background-color: #fff;
      font-size: 16px;
      font-weight: 500;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
}
</style>